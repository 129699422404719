.history-container{
  width: 100%;
 .history__banner{
   position: relative;
   height: 350px;
   width: auto;
    background: {
     image: url("../images/aboutus_1.png");
     size: cover;
     repeat: no-repeat;
     position: center;
   }
   .history__heading {
     text-align: center;
     font-size: 35px;
     font-weight: bold;
     color: white;
     position: absolute;
     top: 50%;
     transform: translateY(-50%);
     left: 0;
     right: 0;
     margin: auto;
     @media all and(min-width: 1024px){
       font-size: 48px;
     }
   }
 }
  .history__section {
    padding: 50px 30px 50px 30px;
    text-align: justify;
    @media all and(min-width: 1024px) {
      padding: 140px 150px 140px 150px;
    }
    .history__section-heading {
      font-size: 35px;
      text-align: center;
      padding-bottom: 8px;
      color: #444343;
      @media all and(min-width: 1024px) {
        font-size: 40px;
      }
    }
    .about-story {
      max-width: 700px;
      margin: auto;
      line-height: 2;
      color: $grey;
    }
    .underline{
      height: 2px;
      background-color: $gold;
      width: 100px;
      margin: auto auto 45px auto;
    }
  }
}
.history__image-second {
  height: 350px;
  width: auto;
  background: {
    image: url("../images/aboutus_2.png");
    size: cover;
    repeat: no-repeat;
    position: center;
  }
}
.history__image-third {
  height: 350px;
  width: auto;
  background: {
    image: url("../images/aboutus_3.png");
    size: cover;
    repeat: no-repeat;
    position: center;
  }
}
