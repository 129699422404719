.mediakit-container {
  width: 100%;
  box-sizing: border-box;
  .mediakit-heading {
    width: auto;
    margin: 0 auto;
    position: relative;
    height: 350px;
    background: {
      image: url("../images/Loza_cognac.png");
      size: cover;
      position: left;
    }
    p {
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      margin: auto;
      color: $gold;
      @media all and(min-width: 768px){
        font-size: 48px;
      }
    }
  }
  .mediakit-wrapper {
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    max-width: 1290px;
    margin: 50px auto 70px auto;
    padding: 30px;
    @media all and(min-width: 1024px) {
      margin: 100px auto 150px auto;
    }
    .media-kit__subheading-golden {
      padding-bottom: 35px;
      text-align: justify;
      @media all and(min-width: 768px) {
        max-width: 400px;
        margin: 0 auto;
      }
      @media all and(min-width: 1024px) {
        max-width: 800px;
        padding-bottom: 90px;
      }
      .golden-heading {
        font-size: 30px;
        font-weight: bold;
        color: $gold;
        margin-bottom: 30px;
        text-align: center;
      }
      p {
        line-height: 1.3;
      }
    }
    .examples {
      margin-bottom: 10px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @media all and(min-width: 768px) {
        flex-wrap: nowrap;
        justify-content: space-around;
      }
      @media all and(min-width: 1024px) {
        justify-content: space-between;
      }
      img {
        margin-bottom: 10px;
        max-width: 100%;
      }
    }
    .download-button {

    }
    a {
      text-decoration: none;
      color: white;
      background-color: $primary;
      border-top: 2px solid $gold;
      border-bottom: 2px solid $gold;
      padding: 8px;
      font-size: 13px;
      display: inline-block;
      width: 130px;
      text-align: center;
      &:hover {
        background-color: #003459;
      }
    }
    .colors {
      padding-bottom: 25px;
    }
    .examples-logo {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @media all and(min-width: 768px) {
        justify-content: space-between;
        margin-bottom: 10px;
      }
      .mediakit-img {
        padding: 2px;
        margin-bottom: 20px;
        max-width: 100%;
        img {
          max-width: 100%;
        }
      }
    }
  }
}