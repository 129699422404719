.footer-container{
  background-color: $primary;
  padding: 20px;
  box-sizing: border-box;
  width:100%;
  flex-shrink: 1;
  @media all and(min-width: 768px){
    padding: 10px;
  }
  .footer-wrapper{
    @media all and(min-width: 768px){
      max-width: 800px;
      margin: 0 auto;
      .logo {
        text-align: center;
      }
    }
    @media all and(min-width: 1024px) {
      display: flex;
      max-width: 1290px;
      width: 100%;
      margin: 0 auto;
  } }
  .name{
    margin-bottom: 20px;
    text-align: center;
    @media all and(min-width: 768px){
      text-align: left;
    }
    .name-logo{
      height: 80px;
      width: auto;
      @media all and(min-width: 768px){
        height: 80px;
        width: auto;
      }
    }
  }
  .footer-menu{
    line-height: 2.5;
    text-align: center;
    width: 100%;
    color: #F2F4F5;
    margin-bottom: 15px;
    font-size: 13px;
    @media all and(min-width: 768px){
      display: flex;
      justify-content: space-around;
      margin-bottom: 25px;
    }
    @media all and(min-width: 1024px){
      align-self: center;
      justify-content: center;
    }
    .rest{
      color: white;
      &:hover {
        color: $gold;
      }
    }
    a{
      text-decoration: none;
    }
    .menu{
      color: white;
      &:hover {
        color: $gold;
      }
      @media all and(min-width: 1024px){
        margin-right: 13px;
      }
    }
  }
  .social-media{
    display: flex;
    margin-bottom: 20px;
    text-align: left;
    justify-content: center;
    @media all and(min-width: 768px){
      justify-content: center;
    }
    @media all and(min-width: 1024px){
      align-self: center;
    }
    .facebook{
      margin-right: 10px;
    }
    .twitter{
      margin-right:10px;
    }
    .instagram {
      margin-right: 10px;
    }
  }
  .bottom-wrapper{
    display: flex;
    border-top: 2px solid white;
    justify-content: space-between;
    padding: 10px;
    font-size: 11px;
    max-width: 1290px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    .rights{
      color: #F2F4F5;
    }
    .terms{
      a {
        text-decoration: none;
        color: #f2f4f5;
        &:hover {
          color: $gold;
        }
      }
    }
  }
}

#scroll {
  position:fixed;
  right:20px;
  bottom:50px;
  cursor:pointer;
  width:50px;
  height:50px;
  background-color:$gold;
  text-indent:-9999px;
  display:none;
  -webkit-border-radius:60px;
  -moz-border-radius:60px;
  border-radius:60px
}
#scroll span {
  position:absolute;
  top:50%;
  left:50%;
  margin-left:-8px;
  margin-top:-12px;
  //height:5px;
  width:0;
  border:8px solid transparent;
  border-bottom-color:#ffffff;
}
#scroll:hover {
  background-color:#6a4b1b;
  opacity:1;filter:"alpha(opacity=100)";
  -ms-filter:"alpha(opacity=100)";
}