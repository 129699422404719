@import "reset";
@import "variables";
@import "mixins";
@import "header";
@import "flexslider";
@import "aboutUs";
@import "ourstory";
@import "footer";
@import "ourcognac";
@import "product";
@import "ageVerification";
@import "404page";
@import "history";
@import "contactpage";
@import "mediakit";
@import "termsOfService";
@import "general";
@import "specialedition";
@import "specialSlider";

body {
  &.modal-active {
    overflow: hidden;
  }
}