html {
  //overflow: hidden;
}
body {
  //overflow-y: scroll;
  //-webkit-overflow-scrolling:touch;
}
.jo {
  width: 200px;
  height: 200px;
  z-index: 9999999999999999999999999;
  background-color: red;
}
.modal-darken {
  background: {
    image: url("../images/dark-wood.png");
    repeat: repeat;
    color: #292018;
  };
  position: fixed;
  width: 100%;
  z-index: 998;
  display: none;
  &.active {
    display: block;
  }
}
// New Two step verification.
.age-verification {
  display: none;
  padding: 10px;
  font-weight: 200;
  color: white;
  position: absolute;
  max-width: 500px;
  width: 100%;
  box-sizing: border-box;
  left: 0;
  right: 0;
  top: 20px;
  margin: auto;
  z-index: 999;
  @media all and(min-width: 1024px){
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }
  &.failed {
    .age-verification__form {
      display: none;
    }
    .age-verification__not-allowed {
      display: block;
    }
  }
  &.active {
    display: block;
  }

  &__language-options {
    max-width: 250px;
    width: 100%;
    margin: 20px auto 60px auto;
    display: flex;
    justify-content: space-between;
    font-size: 0;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    &:focus,
    &:active,
    &:hover {
      background-color: transparent;
    }
    li {
      border-radius: 50%;
      width: 36px;
      height: 36px;
      flex: 0 0 36px;
      background: {
        size: cover;
        position: center;
        repeat: no-repeat;
      };
      box-sizing: border-box;
      align-self: center;
      &:hover {
        box-shadow: 0 0 6px 2px rgba(255,255,255,1);
      }
      &.active {
        box-shadow: 0 0 6px 2px rgba(255,255,255,1);
        border: 2px white solid;
      }
      &[lang=rs]{
        background-image: url('../images/rs-flag.svg');
      }
      &[lang=ru]{
        background-image: url('../images/ru-flag.svg');
      }
      &[lang=fr]{
        background-image: url('../images/fr-flag.svg');
      }
      &[lang=en]{
        background-image: url('../images/en-flag.svg');
      }
    }
  }
  &.hidden {
    display: none;
  }
  &__logo {
    margin-bottom: 20px;
    text-align: center;
    img {
      max-width: 70px;
    }
  }
  &__title {
    color: white;
    text-align: center;
    font-size: 28px;
    margin-bottom: 30px;
  }
  &__language-label,
  &__question {
    margin-bottom: 30px;
    font-size: 20px;
  }
  input {
    appearance: none;
    width: 80px;
    height: 40px;
    background-color: black;
    color: white;
    border: 1px solid $gold;
    padding-left: 5px;
    font-size: 18px;
    &.error {
      border: 1px solid red;
    }
  }
  &__options {
    display: flex;
    justify-content: space-between;
    max-width: 70%;
    width: 100%;
    margin: 0 auto;
  }
  &__option {
    @include button();
    cursor: pointer;
  }
  &__not-allowed {
    display: none;
    &.active {
      display: block;
    }
    line-height: 1.5;
    font-size: 22px;
  }
  &__container {
    width: 100%;
    background-color: rgba(0,0,0, 0.40);
    box-sizing: border-box;
    padding: 30px 20px 40px 20px;
  }
  &__wrapper {
    padding: 12px;
    border-radius: 6px;
    border: 15px solid rgba(0,0,0, 0.40);
    text-align: center;
  }
  &__age {
    max-width: 240px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    label {
      margin-bottom: 5px;
      display: block;
    }
  }
  &__submit {
    @include button();
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 18px;
    border-left: none;
    border-right: none;
  }
  &__cookie-notification {
    font-size: 12px;
    line-height: 1.4;
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
  }
}
