.fourofour-container{
  width: 100%;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  .fourofour-wrapper{
    text-align: center;
    padding: 80px 80px;
    @media all and(min-width: 768px){
      padding: 110px 110px;
    }
    @media all and(min-width: 1024px){
      padding: 150px 150px;
    }
    .heading{
      font-size:50px;
      font-weight: bold;
      color: $gold;
      margin-bottom: 20px;
      @media all and(min-width: 768px){
        font-size: 60px;
        margin-bottom: 30px;
      }
      @media all and( min-width: 1024px){
        font-size: 70px;
        margin-bottom: 45px;
      }
    }
    .subeheading{
      font-size: 30px;
      margin-bottom: 30px;
      @media all and(min-width: 768px){
        font-size: 40px;
        margin-bottom: 40px;
      }
      @media all and(min-width: 1024px){
        font-size: 50px;
        margin-bottom: 50px;
      }
    }
    .safebutton{
      color: white;
      background-color: $primary;
      border-top: 2px solid $gold;
      border-bottom: 2px solid $gold;
      text-decoration: none;
      padding: 8px;
      font-size: 13px;
      display: inline-block;
      width: 100px;
      text-align: center;
      @media all and(min-width: 768px){
        padding: 10px;
        font-size: 16px;
      }
      @media all and(min-width: 1024px){
        padding: 12px;
        font-size: 18px;
      }
    }
  }
}