.aboutus-container {
  width: 100%;
  padding-bottom: 20px;
  .aboutus-wrapper {
    padding: 30px;
    max-width: 1290px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin: 0 auto 20px auto;
    @media all and(min-width: 768px) {
      padding: 20px;
      flex-wrap: nowrap;
      justify-content: space-between;
      flex-direction: row;
      .aboutus__image-bottle,
      .aboutus__text {
        flex: 1;
      }
    }
    .aboutus__text {
      text-align: center;
      margin: auto;
      color: $grey;
      h3 {
        margin-bottom: 10px;
        font-size: 16px;
      }
      h2 {
        font-size: 30px;
        margin-bottom: 10px;
        line-height: 1.3;
      }
      p {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 1.3;
      }
      a {
        color: white;
        background-color: $primary;
        border-top: 2px solid $gold;
        border-bottom: 2px solid $gold;
        text-decoration: none;
        padding: 8px;
        font-size: 13px;
        display: inline-block;
        width: 100px;
        text-align: center;
        &:hover {
          background-color: #003459;
        }
      }
      @media all and(min-width: 768px) {
        text-align: right;
        margin: 0;
        h3 {
          font-size: 30px;
          margin-bottom: 20px;
        }
        h2 {
          font-size: 40px;
          margin-bottom: 30px;
        }
        p {
          font-size: 16px;
          margin-bottom: 30px;
        }
      }
      .aboutus__wrapper{
        @media all and(min-width: 1024px){
          max-width: 600px;
        }
      }
    }
  }
}
.aboutus-image{
  width: 100%;
}
