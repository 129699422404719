.ourcognac-container{
  width: 100%;
  h2{
    text-align: center;
    font-size: 30px;
    color: $gold;
    font-weight: bold;
    padding-top: 100px;
    @media all and(min-width: 1024px){
      font-size: 40px;
      padding-top: 200px;
    }
  }
  .ourcognac-wrapper{
    padding: 20px;
    box-sizing: border-box;
    @media all and(min-width: 1024px){
      display: flex;
      max-width: 1290px;
      width: 100%;
      padding: 0;
      margin: 0 auto 150px auto;

    }
  }
  .cognac{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
    @media all and(min-width: 768px){
      display: flex;
    }
    @media all and(min-width: 1024px){
      display: block;
    }
    .cognac__about{
      width: 100%;
      line-height: 1.3;
      @media all and(min-width: 768px){
        margin: auto;
      }
      p{
        text-decoration: none;
        color: $gold;
        font-weight: bold;
        font-size: 25px;
        @media all and(min-width: 768px){
          font-size: 30px;
        }
        @media all and(min-width: 1024px){
          font-size: 30px;
        }
      }
      a{
        text-decoration: none;
        color: $gold;
        font-size: 18px;
        @media all and(min-width: 768px){
          font-size: 23px;
        }
        @media all and(min-width: 1024px){
          font-size: 20px;
        }
      }
    }
    .cognac-img{
      width: 90%;
      height:auto;
      @media all and(min-width: 768px){
        width: 100%;
        height: auto;
      }
      @media all and(min-width:1024px){
        width: 100%;
      }
    }
    .order{
      @media all and(min-width: 768px){
        order: 2;
      }
    }
  }
}


