.contact__banner {
  position: relative;
  height: 350px;
  width: auto;
  margin-bottom: 50px;
  box-sizing: border-box;
  background: {
    image: url("../images/Contact.png");
    size: cover;
    repeat: no-repeat;
    position: center;
  }
  .contact__cta {
    padding: 30px;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
    max-width: 650px;
    .contact__heading {
      color: $grey;
      font-size: 25px;
      font-weight: bold;
      margin-bottom: 30px;
      @media all and(min-width: 768px) {
        font-size: 48px;
      }
    }
    .contact__summary {
      color: $grey;
      line-height: 1.3;
    }
  }
}
.informations-wrapper {
  display: flex;
  margin: auto;
  width: 100%;
  flex-wrap: wrap;
  max-width: 1290px;
  @media all and(min-width: 768px) {
    flex-wrap: nowrap;
    align-items: baseline;
    margin-bottom: 100px;
  }
  .contact__block {
    text-align: center;
    padding: 0 5px 70px 5px;
    color: $grey;
    max-width: 250px;
    width: 100%;
    margin: 0 auto;
    @media all and(min-width: 1024px) {
      max-width: 280px;
      width: 100%;
      padding: 0;
    }
    img{
      width: auto;
      height: 45px;
      margin-bottom: 10px;
      @media all and(min-width: 1024px) {
        //margin-bottom: 35px;
      }
    }
    .submenu {
      font-size: 30px;
      margin-bottom: 10px;
      @media all and(min-width: 1024px) {
        margin-bottom: 30px;
      }
    }
    a {
      text-decoration: none;
      color: $grey;
    }
    .note {
      margin-bottom: 25px;
      line-height: 1.3;
      @media all and(min-width: 768px) {
        text-align: center;
      }
      @media all and(min-width: 1024px) {
        margin-bottom: 60px;
      }
    }
    .bottom-block{
      text-align: justify;
      max-width: 145px;
      margin: auto;
    }
    .item{
      box-sizing: border-box;
      display: inline-flex;
      .flag-number{
        width: 15px;
        height: 10px;
        margin-right: 5px;
      }
    }
    .number {
      color: $gold;
    }
  }
  .border {
    @media all and(min-width: 768px) {
      height: 200px;
      width: 2px;
      background-color: #444343;
      align-self: center;
    }
  }
}

