.special-slider__heading{
  font-size: 30px;
  color:$gold;
  text-align: center;
  font-weight: bold;
  padding-bottom: 100px;
  @media all and(min-width: 768px){
    font-size: 40px;
  }
}
.special-slider {
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 100px;
  padding-top: 60px;
  max-width: 1290px;
  margin: 0 auto;
  img{
    width: 100%;
    text-align: center;
    @media all and(min-width: 1024px){
      width: 250px;
    }
  }
  .slides {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }
}
