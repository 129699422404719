.specialedition-container{
  width: 100%;
  box-sizing: border-box;
  .specialedition-baner{
    position: relative;
    height: 350px;
    width: auto;
      background: {
        image: url("../images/deluxe_banner.png");
        size: cover;
        repeat: no-repeat;
        position: center;
      }
    .specialedition__heading{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      color: white;
      font-size: 35px;
      font-weight: bold;
      @media all and(min-width: 1024px){
        font-size: 48px;
      }
    }
  }
  .special-edition-wrapper {
    max-width: 960px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding:40px;
    @media all and (min-width: 480px){
      justify-content: space-between;
    }
    .slide {
      flex: 0 0 100%;
      border: 2px solid $gold;
      margin-top: 30px;
      padding: 20px;
      text-align: center;
      box-sizing: border-box;
      @media all and(min-width: 768px){
        flex: 0 0 45%;
      }
    }
      img{
        width: 100%;
        max-width: 70%;
        margin: 0 auto;
        padding-bottom: 15px;
      }
      .exclusive-heading{
        color: $grey;
        font-size: 18px;
        font-family: Roboto, sans-serif;
        line-height: 1.2;
        margin-bottom: 25px;
      }
      .bullets{
        list-style: circle;
        padding-left: 20px;
        text-align: left;
        line-height: 1.5;
      }
    }
  }
.slides {
  padding: 5px;
  margin-bottom: 30px;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
}
  .picture-slider .flex-control-nav {
    &:after {
      content: "";
      clear: both;
      display: table;
    }
    top: 100%;
  }
  .picture-slider {
    position: relative;
    &:after {
      content: "";
      clear: both;
      display: table;
    }
    margin-bottom: 40px;
  }

  .flex-control-nav.flex-control-paging {
    a {
      height: 10px;
      width: 40px;
      border-radius: 0;
      opacity: 0.5;
    }
    .flex-active {
      opacity: 1;
    }
  }

.picture-slider > .slide {
  padding: 0 8px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Create four equal columns that floats next to eachother */
.slide {
  float: left;
  width: 25%;
}

/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  width: 90%;
  max-width: 1200px;
}

/* The Close Button */
.close {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

/* Hide the slides by default */
.mySlides {
  display: none;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* Caption text */
.caption-container {
  text-align: center;
  background-color: black;
  padding: 2px 16px;
  color: white;
}

img.demo {
  opacity: 0.6;
}

.active,
.demo:hover {
  opacity: 1;
}

img.hover-shadow {
  transition: 0.3s;
}