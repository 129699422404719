//sticky footer
html,body {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
}

//font
html {
  font-family: Raleway, sans-serif;
  &[lang = 'ru'] {
    //background-color: red;
    font-family: 'Montserrat', sans-serif;
  }
}
.number{
  font-family: Roboto, sans-serif;
}