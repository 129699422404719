.ourstory-container {
  background: {
    image: url("../images/History_homepage_image.jpg");
    size: cover;
    position: center;
  }
  width: 100%;
  margin: 0 auto;
  position: relative;
  height: 370px;
  flex: 1 0 auto;
  .ourstory-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    color: white;
    box-sizing: border-box;
    padding: 30px;
  }
  .story {
    max-width: 1290px;
    margin: 0 auto;
    h3 {
      font-size: 16px;
      padding-bottom: 10px;
    }
    h2 {
      font-size: 30px;
      padding-bottom: 10px;
      line-height: 1.3;
    }
    p {
      font-size: 16px;
      padding-bottom: 10px;
      line-height: 1.3;
    }
    a {
      display: inline-block;
      text-decoration: none;
      background-color: $primary;
      border-top: 2px solid $gold;
      border-bottom: 2px solid $gold;
      color: white;
      padding: 8px;
      font-size: 13px;
      width: 100px;
      text-align: center;
      &:hover {
        background-color: #003459;
      }
    }
    @media all and(min-width: 768px) {
      text-align: center;
      padding: 20px;
      h3 {
        font-size: 30px;
        padding-bottom: 30px;
      }
      h2 {
        font-size: 40px;
        padding-bottom: 45px;
      }
      p {
        font-size: 16px;
        padding-bottom: 50px;
      }
      @media all and(min-width: 1024px) {
        text-align: left;
      }
    }
  }
}


