.flexslider{
  border: none !important;
  overflow: hidden;
  margin: 0 auto;
  height: 370px;
  width: 100%;
  border-radius: 0!important;
  @media all and(min-width: 1024px){
    height: auto;
  }
}
.flexslider-wrapper{
  width: 100%;
  box-sizing: border-box;
}
.flexslider .slides img{
  height: 370px!important;
  width: auto!important;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  @media all and(min-width: 1024px){
    width: 100%!important;
    height: auto!important;
    position: relative;
    transform: none;
    left: auto;
  }
}
.flex-nav-next{
  a {
    background: url(/assets/plugins/fonts/Hero_arrow.svg);
    font-size: 0 !important;
    &:before {
      content: '' !important;
    }
  }
}
.flex-nav-prev {
  a {
    background: url(/assets/plugins/fonts/Hero_arrow_left.svg);
    font-size: 0 !important;
    &:before {
      content: '' !important;
    }
  }
}
.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: 10px!important;
  text-align: center;
  z-index: 11;
 li a{
   background-color: $gold!important;
 }
}

.cta-block {
  font-family: 'Raleway', sans-serif;
  position: absolute;
  z-index: 11;
  width: 100%;
  max-width: 81%;
  box-sizing: border-box;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  left: 0;
  right: 0;
  margin: auto;
  h1 {
    color: $gold;
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 23px;
  }
  .description {
    color: white;
    margin-bottom: 25px;
    font-size:17px;
  }
  .find-out {
    color: white;
    border: 1px solid $gold;
    text-decoration: none;
    padding: 3px;
    display: inline-block;
  }
  @media all and(min-width: 768px){
    text-align: center;
    max-width: 80%;
    h1{
      font-size: 60px;
      font-weight: normal;
      margin-bottom: 20px;
    }
    .description{
      font-size: 20px;
      margin-bottom: 25px;
    }
    .find-out{
      padding: 12px;
    }
  }
  @media all and (min-width: 1024px) {
    transform: none;
    text-align: right;
    top:80px;
  }
}


