.tearms-container{
  flex: 1 0 auto;
  max-width: 1290px;
  margin: 0 auto;
  padding: 20px 30px 20px 30px;
  line-height: 1.8;
  text-align: justify;
  h2{
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
  }
  h3{
    margin-bottom: 15px;
  }
  p{
    margin-bottom: 10px;
  }
}