@mixin button() {
  display: inline-block;
  text-decoration: none;
  background-color: $primary;
  border-top: 2px solid $gold;
  border-bottom: 2px solid $gold;
  color: white;
  padding: 8px;
  font-size: 13px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: #003459;
  }
}