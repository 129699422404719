.product-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  background: {
    image: url("../images/Background_singlepage.jpg");
    size: cover;
    position: bottom;
    repeat: no-repeat;
  }
  .product-wrapper {
    text-align: center;
    padding: 40px 10px 100px 10px;
    box-sizing: border-box;
    @media all and(min-width: 768px) {
      display: flex;
      max-width: 1290px;
      justify-content: space-between;
    }
    @media all and(min-width: 1024px) {
      width: 100%;
      margin: 0 auto;
    }
    .main-product {
      padding-bottom: 20px;
      color:$grey;
      @media all and(min-width: 768px) {
        flex: 0 1 45%;
        align-self: center;
      }
      @media all and(min-width: 1024px){
        flex: 0 1 600px;

      }
      .product-img {
        width: 90%;
        margin: 0 auto;
        text-align: center;
      }
    }
    .about-product {
      width: 80%;
      background-color: white;
      border: 7px solid $gold;
      text-align: center;
      margin: 0 auto;
      color:$grey;
      @media all and(min-width: 768px) {
        padding: 10px;
        text-align: left;
        flex: 0 1 45%;
        margin: 0;
      }
      @media all and(min-width: 1024px) {
        flex: 0 1 600px;
        -webkit-box-shadow: -18px 16px 5px 0px rgba(193, 193, 187, 1);
        -moz-box-shadow: -18px 16px 5px 0px rgba(193, 193, 187, 1);
        box-shadow: -18px 16px 5px 0px rgba(193, 193, 187, 1);
      }
      .text-wrapper {
        width: 100%;
        text-align: center;
        padding:30px 30px 30px 30px;
        box-sizing: border-box;
        @media all and(min-width: 768px) {
          width: 90%;
        }
        @media all and(min-width: 1024px){
          text-align: left;
          margin: 0;
        }
      }
      .first-section {
        margin-bottom: 40px;
        h3 {
          font-weight: bold;
          font-size: 25px;
          margin-bottom: 10px;
          @media all and(min-width: 768px){
            font-size: 35px;
          }
        }
      }
      .product-description {
        color: $gold;
        margin-bottom: 20px;
        font-size: 16px;
      }
    }
  }
  .second-section {
    .introduction {
      font-style: italic;
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 10px;
    }
    .about-cognac {
      font-size: 16px;
      line-height: 1.5;
    }
  }
}
.product-available{
  width: 80%;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0 auto 20px auto;
  border-top: 3px solid $gold;
  border-bottom: 3px solid $gold;
  color: $grey;
}