.header-container {
  width: 100%;
  background-color: $primary;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  @media all and(min-width: 768px){
    padding: 10px
  }
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 1290px;
    margin: 0 auto;
    font-family: 'Raleway', sans-serif;
    box-sizing: border-box;
    .main-logo {
      width: auto;
      height: 60px;
    }
    @media all and(min-width: 768px) {
      .main-logo {
        width: auto;
        height: 80px;
      }
    }
    .navbar {
      @media all and(min-width: 1024px) {
        align-self: center;
        font-size: 15px;
      }
    }
    .nav {
      display: none;
      position: absolute;
      z-index: 12;
      background-color: #000c1a;
      top: 100%;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      text-align: center;
      @media all and(min-width: 1024px){
        display: flex;
        position: relative;
        background-color: $primary;
      }
      &.active {
        display: inline-block;
      }
      > li {
        list-style-type: none;
        @media all and(min-width: 1100px){
          width: 155px;
        }
      }
      a {
        text-decoration: none;
        color: white;
        padding: 10px;
        display: block;
        &:hover {
          background-color: $gold;
          transition: 0.7s;
      } }
    }
    .hamburger {
      align-self: center;
      .burger {
        background: transparent;
        border: none;
      }
      @media all and(min-width: 1024px) {
        display: none;
      }
    }
    .hamburger .line {
      width: 40px;
      height: 3px;
      background-color: #9e7432;
      display: block;
      margin: 10px auto;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
    .hamburger:hover {
      cursor: pointer;
    }

    #hamburger-1.is-active .line:nth-child(2) {
      opacity: 0;
    }
    #hamburger-1.is-active .line:nth-child(1) {
      -webkit-transform: translateY(13px) rotate(45deg);
      -ms-transform: translateY(13px) rotate(45deg);
      -o-transform: translateY(13px) rotate(45deg);
      transform: translateY(13px) rotate(45deg);
    }
    #hamburger-1.is-active .line:nth-child(3) {
      -webkit-transform: translateY(-13px) rotate(-45deg);
      -ms-transform: translateY(-13px) rotate(-45deg);
      -o-transform: translateY(-13px) rotate(-45deg);
      transform: translateY(-13px) rotate(-45deg);
    }
  }
  .dropdown{
    position: relative;
  }
  .dropbtn{
    display: none;
    background-color: #000c1a;
    width: 100%;
    @media all and(min-width: 1024px){
      width: 35%;
      position: absolute;
    }
  }
  .last:hover .dropbtn{
    display:block;
  }
}
//mobile language picker
.bars{
  align-self: center;
  .lang{
    align-self: center;
    background-color: #021020;
    display: flex;
    justify-content: center;
    @media all and(min-width: 768px){
      background-color: $primary;
    }
    @media all and(min-width: 1024px){
      display: none;
    }
    .language-picker-mobile{
      padding:2px;
      font-size: 14px;
      margin-top: 3px;
    }
    img{
      width: 20px;
      height: 10px;
    }
    a.link{
      padding: 0;
    }
    span{
      display: flex;
      padding: 10px;
      color: white;
    }
  }
}
//desktop language picker
.eyebrow{
  display: none;
  width: 100%;
  background-color: #021020;
  @media all and(min-width: 1024px){
    display: block;
  }
  a{
    text-decoration: none;
    color: white;
  }
  .span{
    color: white;
  }
  .eyebrow-wrapper{
    text-align: right;
    max-width: 1290px;
    width: 100%;
    margin: 0 auto;
  }
  .language{
    display: flex;
    justify-content: flex-end;
    img{
      width: 15px;
      height:10px;
    }
  }
  .language-picker-desktop{
    padding: 7px;
    font-size: 12px;
  }
}
